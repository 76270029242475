import QueryString from 'qs';

const MAX_RETRY = 10;

/**
 * @file This file is mainly created for rsi-logger to run in MF that in standalone mode,
 * Standalone mode doesn't have rsiApi or window.args (debugLog, cage) which rsi-logger  dependency
 * Usage: Execute setupStandAloneModeContext() in a script tag in MFE's index.html
 * If the MFE has user session, execute sendLoginEvent() for logger to pick up user session
 * ```
 * <script>
 *   // wait till all scripts are loaded
 *   window.addEventListener('load',()=>{
 *     RSIUtils.setupStandAloneModeContext();
 *     // if user session is needed
 *     RSIUtils.sendLoginEvent();
 *   })
 * </script>
 *```
 */

class MfeHelper {
  /**
 * Get the query params from the current url
 * The return values are all strings
 * @example
 * getConfigsFromQueryParams(['foo']);
 * // returns {foo:'1'} on http://test.com/?foo=1&bar=2
 *
 * @param paramKeys list of params keys you want to extract from url
 * @returns object with param key and value
 */
  public getFromQueryParams(paramKeys: string[]) {
    const queryParams = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
    const result: { [key: string]: string } = {};
    paramKeys.forEach((key) => {
      if (queryParams[key]) {
        result[key] = decodeURIComponent(String(queryParams[key]));
      }
    });
    return result;
  }

  /**
 * Check if the url pathname starts with a micro-front-end app identifier
 * @example
 * isPathNameBeginWithAppId('rsi-my-mfe');
 * // returns trueon www.microfrontend.com/rsi-my-mfe?var=123
 *
 * @param appIdentifier
 * @returns
 */
  public pathNameBeginsWithAppId(appIdentifier: string) {
    return window.location.pathname.startsWith(`/${appIdentifier}`);
  }

  /**
 * Grab api config and set to window args
 */
  public setApiConfToWindowArgs() {
    const unsubscribe = RSIConfigHandler.subscribe(
      ['api'],
      ({ data }: any) => {
        window.args = { ...window.args, ...data };
      },
    );
    unsubscribe();
  }

  /**
 * In stand alone mode, there is no access to portal modules.
 * and rsi-logger has dependcy on portal's rsiApi
 * Need to mock up rsiApi, RSINavigationHandler, so rsi-logger can run in other MF's standalone mode
 * @returns void
 */
  public setupRsiApi(retry = MAX_RETRY) {
    try {
      if (retry === 0) {
        return;
      }
      if (window.args) {
        const { cage } = window.args;
        const getCagePlayerId = () => {
          try {
            const player = RSIBrowserStorage.get(`player-${cage}`);
            return `${player.CAGE_CODE}-${player.CAGE_PLAYER_ID}`;
          } catch {
            return null;
          }
        };

        window.rsiApi = {
          getPlayer: () => RSIBrowserStorage.get(`player-${cage}`),
          getSessionId: () => RSIBrowserStorage.get(`session-${cage}`),
          getLanguage: () => 'ENG',
          getPlayerIdWithCageCode: () => getCagePlayerId(),
        };

        if (!window.RSINavigationHandler) {
          window.RSINavigationHandler = { getCurrentPage: () => window.location.href };
        }

        return;
      }
      this.setApiConfToWindowArgs();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed in stand alone mode rsiApi setup: ', { error });
    }
    setTimeout(() => this.setupRsiApi(retry - 1), 500);
  }

  /**
 * Manually send a mock login Event for logger to pickup session id and player
 * @returns void
 */
  public sendLoginEvent(retry = MAX_RETRY) {
    if (retry === 0) {
      return;
    }
    if (RSIEventBus && window.rsiApi) {
      // send mock PORTAL_LOGIN_STATE_CHANGE event if session exists
      if (window.rsiApi.getSessionId()) {
        RSIEventBus.publish(
          RSIEventBus.eventTypes.PORTAL_LOGIN_STATE_CHANGE,
          { isLoggedIn: true, sessionId: window.rsiApi.getSessionId() },
          { withHistory: true, toNative: false },
        );
      }
      return;
    }
    setTimeout(() => {
      this.sendLoginEvent(retry - 1);
    }, 500);
  }
}

export default MfeHelper;

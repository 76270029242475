import { applyMixins } from './utils';
import MfeHelper from './utils/MfeHelper';

class RSIStandaloneMfeUtils { }

// eslint-disable-next-line no-redeclare
interface RSIStandaloneMfeUtils extends MfeHelper { }

applyMixins(RSIStandaloneMfeUtils, [MfeHelper]);

export default RSIStandaloneMfeUtils;

export default (derivedCtor: Function, constructors: Function[]) => {
  constructors.forEach((constructor) => {
    Object.getOwnPropertyNames(constructor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(constructor.prototype, name)
        || Object.create(null),
      );
    });
  });
};
